/** Angular Modules **/
import { Injectable } from '@angular/core';

/** Firebase Modules **/
import {
    UserCredential,
    Auth,
    FacebookAuthProvider,
    GoogleAuthProvider,
    OAuthProvider,
    getAuth,
    signInWithPopup,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword
} from 'firebase/auth';
import { User } from 'src/app/models/user.model';

/** Services **/
import { UserService } from 'src/app/services/user.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    initialized = false;

    private auth: Auth;

    constructor(private userService: UserService) {

    }

    /**
     * Init the auth service
     */
    init() {
        this.auth = getAuth();
        this.initialized = true;
    }

    /** FACEBOOK **/

    /**
     * Launch Login with facebook flow and returns the user
     *
     * @returns Promise<User>
     */
    async signInWithFacebook(): Promise<User> {
        const userCredential: UserCredential = await this.loginWithFacebook();
        console.log(userCredential);
        return await this.userService.createOrGetUser(userCredential.user);
    }

    /**
     * Show Login with facebook popup for web
     *
     * @private
     * @returns Promise<UserCredential>
     */
    private async loginWithFacebook(): Promise<UserCredential> {
        const provider = new FacebookAuthProvider();
        return signInWithPopup(this.auth, provider);
    }

    /** GOOGLE **/

    /**
     * Launch Login with google flow and returns the user
     *
     * @returns Promise<User>
     */
    async signInWithGoogle(): Promise<User> {
        const userCredential: UserCredential | void = await this.loginWithGoogle();
        console.log(userCredential);
        return await this.userService.createOrGetUser(userCredential.user);
    }

    /**
     * Show Login with google popup for web
     *
     * @private
     * @returns Promise<UserCredential>
     */
    private async loginWithGoogle(): Promise<UserCredential> {
        const provider = new GoogleAuthProvider();
        return signInWithPopup(this.auth, provider);
    }

    /** APPLE **/

    /**
     * Launch Login with apple flow and returns the user
     *
     * @returns Promise<User>
     */
    async signInWithApple(): Promise<User> {
        const userCredential: UserCredential | void = await this.loginWithAppleWeb();
        console.log(userCredential);
        return await this.userService.createOrGetUser(userCredential.user);
    }

    /**
     * Show Login with apple popup for web
     *
     * @private
     * @returns Promise<UserCredential>
     */
    private async loginWithAppleWeb(): Promise<UserCredential> {
        const provider = new OAuthProvider('apple.com');
        return signInWithPopup(this.auth, provider);
    }

    /**
     * Create a user with Email and Password
     *
     * @param email
     * @param password
     * @returns Promise<User>
     */
    async createUserWithEmailAndPassword(email: string, password: string): Promise<User> {
        const userCredentials = await createUserWithEmailAndPassword(this.auth, email, password);
        return await this.userService.createOrGetUser(userCredentials.user);
    }

    /**
     * Return a User after signin with email and password
     *
     * @param email
     * @param password
     * @returns Promise<User>
     */
    async signInWithEmailAndPassword(email, password): Promise<User> {
        const userSignedIn = await signInWithEmailAndPassword(this.auth, email, password);
        console.log('userSignedIn', userSignedIn);
        return this.userService.createOrGetUser(userSignedIn.user);
    }
}
