/** Angular Modules **/
import { Injectable } from '@angular/core';

/** Firebase Modules **/
import { getApp } from 'firebase/app';
import { User as FirebaseUser } from 'firebase/auth';
import {
    DocumentData,
    DocumentReference,
    Firestore,
    doc,
    getDoc,
    getFirestore,
    setDoc,
    query,
    collection,
    where,
    getDocs,
    updateDoc} from 'firebase/firestore';

/** Models **/
import { User } from 'src/app/models/user.model';
import { Promocode } from 'src/app/models/promocode.model';


@Injectable({
    providedIn: 'root'
})
export class FirestoreService {

    private db: Firestore;
    private userDocRef: DocumentReference<DocumentData>;

    constructor() {
    }

    init(){
        this.db = getFirestore(getApp());
    }

    /**
     * Set User Doc Reference to be used by all service
     *
     * @param uid
     */
    private setUserDoc(uid: string) {
        this.userDocRef = doc(this.db, 'users', uid);
    }

    /**
     * Get a user from Firestore
     *
     * @param uid
     * @returns Promise<User>
     */
    async getUser(uid: string): Promise<User> {
        this.setUserDoc(uid);
        const docSnap = await getDoc(this.userDocRef);
        if (docSnap.exists()) {
            return docSnap.data() as User;
        } else {
            console.log(`No user found with uid ${uid}`);
            return null;
        }
    }

    /**
     * Create a User in firestore
     *
     * @param firebaseUser
     * @param userLocale
     */
    async createUser(firebaseUser: FirebaseUser, userLocale: string = 'es'): Promise<User> {
        const newUser = FirestoreService.createDefaultUser(firebaseUser, userLocale);
        await setDoc(this.userDocRef, newUser);
        console.log('User created: ', newUser);
        return newUser;
    }

    /**
     * Return a default User
     *
     * @param firebaseUser
     * @param userLocale
     * @private
     */
    private static createDefaultUser(firebaseUser: FirebaseUser, userLocale: string = 'es'): User {
        return {
            uid: firebaseUser.uid,
            email: firebaseUser.email,
            name: firebaseUser.displayName ? firebaseUser.displayName : '',
            photoURL: firebaseUser.photoURL,
            isOnboarding: false,
            createdAt: new Date().toISOString(),
            lastLogin: new Date().toISOString(),
            appPremiumManual: false,
            hasUsedChat: false,
            lang: userLocale,
            version: 1
        };
    }

    /**
     * Return a promocode or void if it doesn't exist
     *
     * @param promocode
     */
    async getPromocode(promocode: string): Promise<Promocode> {
        let resCode: Promocode = null;
        const q = query(
            collection(this.db, 'promocodes'),
            where('code', '==', promocode)
        );
        const querySnapshot = await getDocs(q);
        if (querySnapshot.empty) {
            return resCode;
        } else {
            console.log('found!');
            querySnapshot.forEach((document) => {
                // doc.data() is never undefined for query doc snapshots
                resCode = {
                    ...document.data() as Promocode,
                    id: document.id
                };
                console.log(resCode);
            });
            return resCode;
        }
    }

    /**
     * Mark a promocode as used
     *
     * @param promocode
     */
    async updatePromocode(promocode: Promocode) {
        return updateDoc(doc(this.db, 'promocodes', promocode.id), {
            used: true,
            dateUsed: new Date().getTime()
        });
    }

    /**
     * Update a User in firestore
     *
     * @param changes Partial<User>
     */
    async updateUser(changes: Partial<User>): Promise<void> {
        return updateDoc(this.userDocRef, changes);
    }
}
