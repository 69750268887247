// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  firebase: {
    apiKey: 'AIzaSyD3Oho96OElgT3nHk5OxPmZvoCEraCtvUo',
    authDomain: 'lullaai-app.firebaseapp.com',
    databaseURL: 'https://lullaai-app.firebaseio.com',
    projectId: 'lullaai-app',
    storageBucket: 'lullaai-app.appspot.com',
    messagingSenderId: '800765648298',
    appId: '1:800765648298:web:39765e28bc93a2f1',
    measurementId: 'G-JE531XRJQJ',
    functionEndPoint: 'https://us-central1-lullaai-app.cloudfunctions.net/'
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
