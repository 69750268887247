/** Angular Modules **/
import { Injectable } from '@angular/core';

/** Firestore Modules **/
import { User as FirebaseUser } from 'firebase/auth';

/** Third Party Modules **/
import { TranslocoService } from '@ngneat/transloco';

/** Services **/
import { FirestoreService } from 'src/app/services/firestore.service';

/** Models **/
import { User } from 'src/app/models/user.model';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    user: User;

    constructor(private firestoreService: FirestoreService,
                private translocoService: TranslocoService) {
    }

    /**
     * Create or get a user from Firestore
     *
     * @param firestoreUser
     */
    async createOrGetUser(firestoreUser: FirebaseUser): Promise<User> {
        let user = await this.firestoreService.getUser(firestoreUser.uid);
        if (user) {
            this.user = user;
            return user;
        }
        user = await this.firestoreService.createUser(firestoreUser, this.translocoService.getActiveLang());
        this.user = user;
        return user;
    }

    async checkPromocode(promocode: string) {
        const code = await this.firestoreService.getPromocode(promocode);
        if (code && code.company === 'HERO') {
            const from = code.code.slice(0,5) === 'HEROB' ? 'HERO-BASIC' : 'HERO-PLAN';

            if (from === 'HERO-PLAN' && code.used) {
                return false;
            }

            // Update user with promocode and from parameter
            const partialUser: Partial<User> = {
                promocode: code.code,
                promocodeUid: code.id,
                from
            };

            if (this.user.version) {
                console.log('this.user.version', this.user.version);
                // Deprecated
                partialUser.version = this.user.version + 1;
            }
            console.log('partialUser', partialUser);
            await this.firestoreService.updateUser(partialUser);
            if (from === 'HERO-PLAN') {
                await this.firestoreService.updatePromocode(code);
            }
            return true;
        } else {
            return false;
        }
    }
}
