/** Angular modules **/
import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

/** Third Party modules **/
import { initializeApp } from 'firebase/app';

/** Services **/
import { AuthService } from 'src/app/services/auth.service';
import { FirestoreService } from 'src/app/services/firestore.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(private authService: AuthService,
              private firestoreService: FirestoreService) {
    this.initApp();
  }

  async initApp() {
    initializeApp(environment.firebase);
    console.log('-- APPINIT: Firebase App initialized');
    await this.authService.init();
    console.log('-- APPINIT: Firebase Auth initialized');
    await this.firestoreService.init();
    console.log('-- APPINIT: Firebase Firestore initialized');
  }
}
